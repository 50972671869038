@import '../../../../scss/theme-bootstrap';

.split-feature {
  position: relative;
  overflow: hidden;
  text-align: $ldirection;
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    &.split-feature--left {
      @include breakpoint($landscape-up) {
        flex-direction: row-reverse;
      }
    }
  }
  &__media--maxed {
    overflow: hidden;
  }
  &__media-wrapper {
    width: 100%;
    @include breakpoint($landscape-up) {
      align-items: center;
      display: flex;
      max-width: 50%;
    }
  }
  &__content-wrapper {
    position: relative;
    height: 100%;
    display: flex;
  }
  &__container {
    height: auto;
    width: 100%;
    @include breakpoint($landscape-up) {
      max-width: 50%;
      margin-#{$ldirection}: auto;
      display: flex;
      align-self: center;
    }
    &--positioned {
      &-center {
        align-self: center;
      }
      &-top {
        align-self: flex-start;
      }
      &-bottom {
        align-self: flex-end;
      }
    }
    .split-feature--left & {
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: auto;
        margin-#{$ldirection}: unset;
      }
    }
  }
  &__menu {
    display: flex;
    position: absolute;
    z-index: 3;
    bottom: 10px;
    #{$rdirection}: 10px;
    cursor: pointer;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__slide-button {
    border: 0;
    background-color: $color-black;
    display: block;
    height: 2px;
    width: 2px;
    outline: 0;
    line-height: 2px;
    padding: 2px;
    cursor: pointer;
    border-radius: 8px;
    margin: 3px;
    .split-feature--dots-white & {
      background-color: $color-white;
    }
    .split-feature--dots-black & {
      background-color: $color-black;
    }
  }
  &__content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    .content-block__line {
      padding-bottom: 23px;
      @include breakpoint($landscape-up) {
        padding-bottom: 27px;
      }
      &--eyebrow {
        padding-bottom: 43px;
      }
    }
  }
  &__content-media {
    margin: auto;
  }
  &__feature-media {
    display: inline-flex;
  }
  &__button-wrapper {
    margin-bottom: 33px;
  }
  &:not([data-clickable]) label {
    cursor: default;
  }
  &__trigger:checked ~ &__media-wrapper:not(.split-feature--no-content) {
    position: absolute;
    z-index: 1;
    visibility: visible;
    @include breakpoint($landscape-up) {
      position: relative;
    }
  }
  &__trigger:checked ~ &__container {
    visibility: hidden;
    @include breakpoint($landscape-up) {
      visibility: visible;
    }
  }
  &__trigger:checked ~ &__menu {
    > li:last-of-type {
      transform: scale(1.5);
    }
    > li {
      background-color: $color-white;
    }
    &.split-feature--dots-black {
      > li {
        background-color: $color-black;
      }
    }
  }
  &__trigger:not(:checked) ~ &__media-wrapper:not(.split-feature--no-content) {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    @include breakpoint($landscape-up) {
      position: relative;
      visibility: visible;
    }
  }
  &__trigger:not(:checked) ~ &__container {
    visibility: visible;
  }
  &__trigger:not(:checked) ~ &__menu {
    > li:first-of-type {
      transform: scale(1.5);
    }
  }
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
}
